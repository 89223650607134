import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useFormik } from "formik"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import makeList from "../utils/makeList"

import Layout from "../components/Layout"
import { Grid, Col } from "../components/styles/Grid"
import { Section, ParagraphWrapper } from "../components/styles/Container"
import GlobalStyles from "../components/styles/GlobalStyles"

import "../components/styles/klaus-gridski.css"

import Button from "../components/styles/Button"
import Arrow from "../img/icons/arrow.svg"

const ArrowIcon = styled.img`
  height: 20px;
`

const HeadlineWrapper = styled.div`
  margin-top: 10rem;
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`

const MobHeadlineWrapper = styled.div`
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`

// PRICE +  CURRENCY
const Price = ({ price, rate, currency }) => {
  return ` ${(price * rate).toFixed(2)}${currency}`
}

// CUSTOMIZE SECTION
const Customize = ({ basePrice = 249 }) => {
  const [usd, setUSD] = useState(1)
  const [pound, setPound] = useState(1)
  const [exchangeRate, setExchangeRate] = useState(1)
  const [currency, setCurrency] = useState("€")
  const [currencyValue, setCurrencyValue] = useState("EUR")
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    const getExchangeRates = async () => {
      try {
        const res = await fetch(
          "https://api.exchangeratesapi.io/latest?base=EUR&symbols=USD,GBP"
        )
        const { rates } = await res.json()
        setUSD(rates.USD)
        setPound(rates.GBP)
      } catch (err) {
        console.error(err.message)
      }
    }
    getExchangeRates()
  }, [])

  /* BUTTON */

  const changeCurrency = e => {
    const value = e.target.value
    setCurrencyValue(value)
    switch (value) {
      case "EUR":
        setExchangeRate(1)
        setCurrency("€")
        break
      case "USD":
        setExchangeRate(usd)
        setCurrency("$")
        break
      case "GBP":
        setExchangeRate(pound)
        setCurrency("£")
        break
      default:
        setExchangeRate(1)
        break
    }
  }

  const formik = useFormik({
    initialValues: {
      playtime: "3",
      unmastered: false,
      multitracks: false,
      express: false,
      revision: false,
      message: "",
      email: "",
      price: "",
    },
    onSubmit: values => {
      values.price = `${(total * exchangeRate).toFixed(2)}${currency}`
      values.playtime = `${values.playtime}min`

      let formData = makeList(values)

      setIsSending(true)
      fetch(process.env.GATSBY_MAILER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.GATSBY_BEARER}`,
        },
        body: JSON.stringify({
          recipient: process.env.GATSBY_MAIL_RECIPIENT,
          subject: "Customize Production Form",
          htmltext: formData,
        }),
      })
        .then(() => {
          setIsSending(false)
          navigate("/thanks")
        })
        .catch(error => {
          setIsSending(false)
          alert(error)
        })
    },
  })

  const [total, setTotal] = useState(basePrice)
  useEffect(() => {
    // Reset price, since we'll recalculate it now
    let price = basePrice

    // Get the price for playtime
    switch (formik.values.playtime) {
      case "3":
        price += 0 * exchangeRate
        break
      case "4":
        price += 49 * exchangeRate
        break
      case "5":
        price += 79 * exchangeRate
        break
      default:
        price += 0 * exchangeRate
        break
    }

    // Get the price for options
    const { unmastered, multitracks, express, revision } = formik.values

    if (unmastered) {
      price += 9 * exchangeRate
    }
    if (multitracks) {
      price += 49 * exchangeRate
    }

    // Check express delivery as last option, since the price must be clear before
    if (express) {
      price += (price / 2) * exchangeRate
    }
    // Revisions are excluded
    if (revision) {
      price += 69 * exchangeRate
    }

    setTotal(price)
  }, [formik.values, basePrice, exchangeRate])

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 75em)",
  })
  const isTablet = useMediaQuery({
    query: "(min-device-width: 48em) and (max-device-width: 75em)",
  })
  const isMobile = useMediaQuery({
    query: "(max-device-width: 48em)",
  })

  // RIGHT SUMMARY SECTION

  const Summary = ({ fields, price }) => (
    <div>
      <Grid gap="5">
        <Col>
          {" "}
          <h3>your selection</h3>
        </Col>
        <Col>
          {/* <ParagraphWrapper>
            One Song <br />
            Mastering <br /> HQ Audio File
          </ParagraphWrapper> */}
          <br />
          <div className="labels">
            <strong>CHOSEN PLAYTIME</strong>
            <p>{fields.playtime} min</p>
          </div>
        </Col>
        <Col>
          <div className="added-extras">
            <strong>
              ADDED EXTRAS <br /> from
              <Price
                price={basePrice}
                rate={exchangeRate}
                currency={currency}
              />
              <br />
            </strong>
            <ul>
              {fields.unmastered ? <li>get the unmastered mix</li> : null}
            </ul>
            <ul>
              {fields.multitracks ? <li>get all the multitracks</li> : null}
            </ul>
            <ul>{fields.express ? <li>3 days express delivery</li> : null}</ul>
            <ul>
              {fields.revision ? (
                <li>Unlimited revisions for 4 weeks </li>
              ) : null}
            </ul>
          </div>
        </Col>
      </Grid>
      <select onChange={changeCurrency} value={currencyValue}>
        <option value="EUR">EUR</option>
        <option value="USD">USD</option>
        <option value="GBP">GBP</option>
      </select>
      <br />
      <strong>TOTAL</strong>
      <strong className="price">
        <Price price={price} rate={exchangeRate} currency={currency} />
      </strong>
      <br />
    </div>
  )

  return (
    <Layout>
      <GlobalStyles />
      {isDesktop && (
        <HeadlineWrapper>
          <h2>Customize</h2>
        </HeadlineWrapper>
      )}
      {(isMobile || isTablet) && (
        <MobHeadlineWrapper>
          <h2>Customize</h2>
        </MobHeadlineWrapper>
      )}
      <Section>
        <form onSubmit={formik.handleSubmit} name="customize-production">
          <Grid gap="7" bg="2">
            <Col>
              <h3>choose details</h3>
              <ParagraphWrapper>
                Qualix will remix your song or produce an instrumental from
                scratch for you. For remixing he needs at least a finished vocal
                stem, more stems from the original song can also be added of
                course. For an instrumental production he needs at least two
                reference tracks to get the idea of your vision. You will
                receive an individual production with unique sounds and melodies
                (samples only used for drums and percussion elements).
              </ParagraphWrapper>
              <ParagraphWrapper>
                - 21 days delivery <br />
                - 10 days express delivery <br /> - 2 revisions included
              </ParagraphWrapper>

              <div>
                <input
                  id="check2"
                  className="tab flex vertical"
                  type="checkbox"
                />
                <div className="flex between labels">
                  <label htmlFor="check2" className="tab-label">
                    PLAYTIME
                  </label>
                  <label htmlFor="check2">
                    <ArrowIcon src={Arrow}></ArrowIcon>
                  </label>
                </div>

                <div className="tab-content">
                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="3"
                      defaultChecked
                    />
                    3min (default)
                  </label>

                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="4"
                    />
                    4min ( +
                    <Price price={49} rate={exchangeRate} currency={currency} />
                    )
                  </label>

                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="5"
                    />
                    5min ( +
                    <Price
                      price={79}
                      rate={exchangeRate}
                      currency={currency}
                    />{" "}
                    )
                  </label>
                </div>
              </div>
              <div>
                <input
                  id="check1"
                  className="tab flex vertical"
                  type="checkbox"
                />
                <div className="flex between labels">
                  <label htmlFor="check1" className="tab-label">
                    OTHER OPTIONS
                  </label>
                  <label htmlFor="check1">
                    <ArrowIcon src={Arrow}></ArrowIcon>
                  </label>
                </div>

                <div className="tab-content">
                  <label>
                    <input
                      type="checkbox"
                      name="unmastered"
                      onChange={formik.handleChange}
                    />
                    get the unmastered mix ( +
                    <Price price={9} rate={exchangeRate} currency={currency} />)
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="multitracks"
                      onChange={formik.handleChange}
                    />
                    get all the multi tracks ( +
                    <Price
                      price={49}
                      rate={exchangeRate}
                      currency={currency}
                    />{" "}
                    )
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="express"
                      onChange={formik.handleChange}
                    />
                    7 days express delivery (+50%)
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="revision"
                      onChange={formik.handleChange}
                    />
                    unlimited revisions for 4 weeks ( +
                    <Price price={69} rate={exchangeRate} currency={currency} />
                    )
                  </label>
                </div>
              </div>
              <div>
                <span className="labels" style={{ display: "block" }}>
                  DROP ME A LINE
                </span>
                <textarea
                  name="message"
                  onChange={formik.handleChange}
                  rows="8"
                  placeholder="Link to your files, additional information etc."
                ></textarea>
                <label
                  htmlFor="email"
                  className="labels"
                  style={{ display: "block" }}
                >
                  YOUR EMAIL
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  placeholder="your@email.com"
                  required
                />
              </div>
            </Col>
            <Col>
              <Summary fields={formik.values} price={total} />
              <Button type="submit" disabled={isSending}>
                {isSending ? "sending..." : "send request"}
              </Button>
            </Col>
          </Grid>
        </form>
      </Section>
      <Section></Section>
    </Layout>
  )
}

export default Customize
